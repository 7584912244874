import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import chienFusé from '../components/fonts/chien-fusé.png'
import chienSurf from '../components/fonts/chien-surf.png'
import chienPlage from '../components/fonts/chien-plage.png'
import chienNuage from '../components/fonts/chien-nuage.png'
import chienAstro from '../components/fonts/chien-astro.png'



const images = [
  { src: chienFusé, alt: 'Image 1' },
  { src: chienSurf, alt: 'Image 2' },
  { src: chienPlage, alt: 'Image 3' },
  { src: chienNuage, alt: 'Image 4' },
  { src: chienAstro, alt: 'Image 5' },
];

const Image = styled.img<{ top: number; left: number }>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: 100px;
  height: 100px;

  @media (max-width: 768px) {
    width: 50px;
    height: 50x;
  }
`;

const FloatingImages: React.FC = () => {
  const [positions, setPositions] = useState(
    images.map(() => ({
      top: Math.random() * (window.innerHeight - 600), // Début plus bas
      left: Math.random() * (window.innerWidth - 350),
      directionX: Math.random() > 0.5 ? 1 : -1,
      directionY: Math.random() > 0.5 ? 1 : -1,
    }))
  );

  const animationFrameId = useRef<number | null>(null);

  const updatePositions = () => {
    setPositions((prevPositions) =>
      prevPositions.map((pos) => {
        let newTop = pos.top + pos.directionY * 1.8; // Ajustez la vitesse si nécessaire
        let newLeft = pos.left + pos.directionX * 1.8;

        if (newTop <= 0 || newTop >= window.innerHeight - 300) {
          pos.directionY *= -1;
        }

        if (newLeft <= 0 || newLeft >= window.innerWidth - 300) {
          pos.directionX *= -1;
        }

        return {
          ...pos,
          top: newTop,
          left: newLeft,
        };
      })
    );
    animationFrameId.current = requestAnimationFrame(updatePositions);
  };

  useEffect(() => {
    animationFrameId.current = requestAnimationFrame(updatePositions);

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  return (
    <div>
      {images.map((image, index) => {
        const { top, left } = positions[index];
        return (
          <Image
            key={index}
            src={image.src}
            alt={image.alt}
            top={top}
            left={left}
            className="floating-image"
          />
        );
      })}
    </div>
  );
};

export default FloatingImages;
