import React, { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import styled, { keyframes } from "styled-components";
import FloatingImages from "./FloatingImages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faTelegram } from "@fortawesome/free-brands-svg-icons";
import dexscreenerIcon from "./fonts/dexscreener.png";
import dollarsIcon from './dollars.png';
import chien from '../components/fonts/chien.jpeg'
import "./Home.css";
const concept1 = (
  <>
    <title>📊How it works ?</title>
    <br></br>
    1. Pump fun launch 🚀<br></br>
    We launch on pump fun and send the link to the Telegram Group.<br></br>
    <br></br>
    2. Get rid of bots 🤖<br></br>
    Do not buy in the first 3 mins. We use it to get rid of « taking instant
    profit » bots<br></br>
    <br></br>
    3. Buy buy buy 💹<br></br>
    Channels members ape in.<br></br>
    We launch micro-buying strategy using custom bots to always be on home page.
    <br></br>
    <br></br>
    4. Radyium launch & first profits💸<br></br>
    Once launched on Raydium, coin do an average of 200% in the first 5min. We
    advise to sell 30% of your bag.<br></br>
    <br></br>
    5. Grow 📈<br></br>
    Marketing is launched and project is growing. Website is launched.
    <br></br>
    <br></br>
    💅 What is planned for marketing :<br></br>
    <br></br>
    We buy 5% at launch🚀, then<br></br>
    <br></br>- We sell 1% at 50k to pay for Dextscreener update
    <br></br>- We sell 1% at 140K to pay for callers<br></br>- We sell 2% at
    300k to pay for dextools update,ads .<br></br>- We sell le last 1% between
    500k and 1M to allow the community to grow<br></br>
    <br></br>
    Most importantly you need a community who embraces the life of a Jeet and
    learns to take small <br></br>~profits on the way up and never dump your
    whole bag. The goal isn't 10x, the goal is to make profit every launch.
    <br></br>
  </>
);

const concept2 = (
  <>
    GIPPY is a community focused cryptocurrency created by one of the
    original Dogecoin shibes from 2013. <br></br> It was created for one
    purpose, to create a new and fun community just like the original Dogecoin
    community.{" "}
  </>
);

const concept3 = (
  <>
    GIPPY tipping is supported in telegram <br></br>
    Additionally, GIPPY tipping is supported on twitter! Send a direct
    message to @GIPPY on Reddit to get started.
  </>
);

const Home: React.FC = () => {
  const particleInit = useCallback(async (engine: any) => {
    await loadFull(engine);
  }, []);

  const particleConfig = {
    fullScreen: {
      enable: true, // Disable full screen to allow custom positioning
      ZIndex: 0,
    },
    detectRetina: true,
    background: {
      color: "#000"
    },
    fpsLimit: 60,
    emitters: {
      life: {
        count: 0,
        duration: 0.4,
        delay: 2
      },
      rate: {
        delay: 0.01,
        quantity: 1
      },
      size: {
        width: 50,
        height: 0
      },
      position: {
        y: 100,
        x: 50
      }
    },
    particles: {
      number: {
        value: 1
      },
      life: {
        count: 1
      },
      shape: {
        type: "line"
      },
      size: {
        value: { min: 1, max: 60 },
        animation: {
          enable: true,
          sync: true,
          speed: 100,
          destroy: "min"
        }
      },
      stroke: {
        color: {
          value: "#00000"
        },
        width: 3
      },
      rotate: {
        path: true
      },
      move: {
        enable: true,
        speed: { min: 10, max: 20 },
        outModes: {
          default: "destroy",
          top: "none"
        },
        trail: {
          fillColor: "#000",
          enable: true,
          length: 10
        }
      },
      destroy: {
        mode: "split",
        split: {
          count: 1,
          factor: { value: 1 / 3 },
          rate: {
            value: 100
          },
          particles: {
            color: {
              value: ["#FFD700", "#00000", "#00000", "#00000", "#00000"]
            },
            stroke: {
              width: 0
            },
            number: {
              value: 0
            },
            collisions: {
              enable: false
            },
            opacity: {
              value: 1,
              animation: {
                enable: true,
                speed: 0.6,
                minimumValue: 0.1,
                sync: false,
                startValue: "max",
                destroy: "min"
              }
            },
            shape: {
              type: "star"
            },
            size: {
              value: { min: 2, max: 3 },
              animation: {
                enable: false
              }
            },
            life: {
              count: 1,
              duration: {
                value: {
                  min: 1,
                  max: 2
                }
              }
            },
            move: {
              enable: true,
              gravity: {
                enable: false
              },
              speed: 2,
              direction: "none",
              random: true,
              straight: false,
              outMode: "destroy"
            }
          }
        }
      }
    }
  };

  return (
    <div className="home">
      <HomeContainer>
        <SocialSection>
          <SocialButton href="https://pump.fun/9xq2BWeETe8n5hatbRR5jWzp4wbQfcbZm8PTWa1Joopa">
            <img className="icon" src={dollarsIcon} alt="Buy Icon" width="50" height="50" />
            <ButtonText>BUY</ButtonText>
          </SocialButton>
          <SocialButton href="">
            <img className="icon" src={dexscreenerIcon} alt="Dexscreener Icon" width="50" height="50" />
            <ButtonText> Dexscreener</ButtonText>
          </SocialButton>
          <SocialButton
            href="https://x.com/GIPPYsol"
            target="_blank"
          >
            <FontAwesomeIcon icon={faTwitter} />
            <ButtonText>Twitter</ButtonText>
          </SocialButton>
          <SocialButton href="https://t.me/GIPPYsol" target="_blank">
            <FontAwesomeIcon icon={faTelegram} />
            <ButtonText>Telegram</ButtonText>
          </SocialButton>
        </SocialSection>
        <FloatingImages />
        <img src={chien} className='chien' alt="Promotional GIF" />
        <h2>
          WELCOME TO GIPPY
          <p>YOUR ULTIMATE SPACE FOR MEMES AND COMMUNITY FUN</p>
        </h2>
        {[concept1, concept2, concept3].map((concept, index) => (
          <Section key={index}>
            <SectionText> {concept}</SectionText>
          </Section>
        ))}
        <ParticlesContainer>
        <Particles
          id="tsparticles"
          init={particleInit}
          options={particleConfig as any}
        />
      </ParticlesContainer>
      </HomeContainer>
    </div>
  );
};

const ParticlesContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Make sure it is behind other content */
`;

const HomeContainer = styled.div`
  padding: 20px;
   font-family: "Marker Felt", fantasy;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 0.8em;
  text-transform: uppercase;
  position: relative; /* Ensure the home container is above particles */

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 0.6em;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const Section = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  &.visible {
    opacity: 1;
    transform: translateX(0);
    animation: ${slideIn} 1s ease forwards;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const SectionText = styled.div`
  font-family: "Marker Felt", fantasy;
   font-size: 2em !important;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 0.8em !important;
  }
`;

const SocialSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    gap: 10px;
    margin-top: 10px;
  }
`;

const SocialButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border: 3px solid #ffd700;
  border-radius: 10px;
  text-decoration: none;
    font-family: "Marker Felt", fantasy;
     font-size: 1.4em;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #ffd700;
    color: #000;
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
    font-size: 0.8em;
  }
`;

const ButtonText = styled.span`
  margin-top: 5px;
  font-size: 0.8em;

  @media (max-width: 768px) {
    margin-top: 3px;
    font-size: 0.7em;
  }
`;

export default Home;
