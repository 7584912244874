import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import styled from 'styled-components';
import home from '../components/fonts/home.png'
import jppy from '../components/fonts/jippy.png'

const Navbar = () => {
  return (
    <nav className="navbar">
      <img src={jppy} width="100px" height="40px"></img>
      <div className="links">
        <img src={home} width="100px" height="40px" ></img>
      </div>
    </nav>
  );
}

const GIFImage = styled.img`
  width: 250px; /* Adjust the size as needed */
  height: auto;
  margin-right: 10%;
  margin-bottom: 20px;
  margin-top: 20%;

  @font-face: {
    font-family: "GT Maru", cursive;
   } 

  @media (max-width: 800px) {
    width: 100px;
    margin-bottom: 10px;
    margin-right 30%;
  }
`;


export default Navbar;

